<template>
	<div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 gap-6">
		<div class="lg:col-span-2 pb-12 md:py-12">
			<div class="text-lg pb-6">
				For general queries, including partnership opportunities,
				contact below:
			</div>
			<div v-for="(item, i) in support_address" :key="'sp_' + i">
				<div
					class="text-2xl font-bold pb-6 text-blue-500"
					v-if="item.data_alias == 'support-address-header'"
					v-html="item.data_value"
				></div>
				<div
					v-if="item.data_alias == 'support-address-header'"
					class="ui hidden divider"
				></div>
				<div
					class="text-3xl font-bold"
					v-if="item.data_alias == 'support-address-company-name'"
					v-html="item.data_value.replace(/\n/g, ' <br />')"
				></div>
				<div
					class="text-xl font-semibold text-gray-400 py-3"
					v-if="item.data_alias == 'support-address-company-address'"
					v-html="item.data_value.replace(/\n/g, ' <br />')"
				></div>
				<div
					class="text-xl font-semibold text-blue-500 underline py-3"
					v-if="item.data_alias == 'support-address-company-email'"
				>
					<i class="mail outline icon"></i>
					<a :href="'mailto:' + item.data_value">
						{{ item.data_value }}
					</a>
				</div>
				<div
					class="text-xl font-semibold text-gray-400"
					v-if="item.data_alias == 'support-address-company-phone'"
				>
					<i class="phone square icon"></i>
					<span v-html="item.data_value"></span>
				</div>
			</div>
		</div>
		<div
			class="lg:col-span-3 md:p-12 md:shadow-lg md:border md:border-gray-100 md:rounded-2xl"
			v-html="support_iframe"
		></div>
	</div>
</template>

<script>
import service_manager from "../api/service_manager.js";
export default {
	data() {
		return {
			model_item: {
				ticket_email: "",
				ticket_phone: "",
				ticket_message: ""
			},
			support_address: [],
			support_iframe: ""
		};
	},
	methods: {
		submitForm() {
			var app = this;
			app.$validator.validateAll().then((result) => {
				if (result) {
					service_manager(
						"post",
						"/api/data/post/support_tickets",
						app.model_item
					)
						.then((res) => {
							// console.log(res);
							return service_manager(
								"post",
								"/api/data/email/template",
								{
									receiver:
										app.model_item.ticket_email +
										"," +
										"121@paydeck.in",
									template_name: "Support",
									email_params: {
										email: app.model_item.ticket_email,
										phone: app.model_item.ticket_phone,
										message: app.model_item.ticket_message
									}
								}
							);
						})
						.then((res) => {
							app.model_item = {
								ticket_email: "",
								ticket_phone: "",
								ticket_message: ""
							};
							app.$validator.reset();
							app.swal({
								title: "Thanks!",
								text: "Your Query Has been submitted successfully!\n Allow us to get in touch with you within 24 business hours.\n\n For Quick Solution you may write to us at: 121@paydeck.in using your registered email address.",
								icon: "success",
								success: true
							});
						})
						.catch((err) => {
							// console.log(err);
						});
				}
			});
		},
		getSiteData() {
			service_manager("post", "/api/data/fetch/site_data/0/0", {
				query: {
					data_alias: {
						$regex: "support-"
					}
				},
				show: {},
				sort: { data_type: 1 }
			})
				.then((res) => {
					// console.log(
					//     "Site Data ==================================="
					// );
					// // console.log(res.data.response.data);
					var site_data = res.data.response.data;
					for (var i = 0; i < site_data.length; i++) {
						// console.log(site_data[i]);
						if (
							site_data[i].data_alias.indexOf(
								"support-iframe-html"
							) != -1
						) {
							this.support_iframe = site_data[i].data_value;
							// console.log(this.support_iframe);
						} else {
							this.support_address.push(site_data[i]);
						}
					}
					// console.log(this.support_address);
				})
				.catch((err) => {
					// console.log(err);
				});
		}
	},
	mounted() {
		this.getSiteData();
	}
};
</script>

<style scoped></style>
