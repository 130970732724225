import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Page from "../views/Page.vue";
import Page404 from "../views/404.vue";

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/page/:link",
		alias: "/page/mobile/:link",
		name: "Page",
		component: Page,
		props: true
	},
	{
		path: "/:pathMatch(.*)*",
		name: "Page404",
		component: Page404,
		props: true
	}
];

const router = createRouter({
	mode: "history",
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
