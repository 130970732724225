var axios = require("axios");
// import store from "../store";
import file_upload from "./file_upload.js";

export default (method, path, params) => {
	return new Promise((resolve, reject) => {
		var base_url = "http://192.168.1.11:3332" + path;
		if (process.env.NODE_ENV == "production") {
			// SERVER_PRODUCTION
			base_url = "https://api2.paydeck.in" + path;
		}
		if (process.env.NODE_ENV == "staging_prod") {
			// SERVER_STAGING_PROD
			base_url = "https://api3.paydeck.in" + path;
		}
		if (process.env.NODE_ENV == "staging") {
			// SERVER_STAGING
			base_url = "https://pd.omnibuz.com" + path;
		}

		base_url = "https://api2.paydeck.in" + path;

		// try {
		//     user_session_key = this.getUser.session.user_session_key;
		// } catch (err) {
		//     reject(err);
		// }

		var headers = {
			headers: {
				"content-type": "application/json"
				// authorization: user_session_key
			}
		};

		console.warn(
			method.toUpperCase(),
			base_url,
			JSON.stringify(params, undefined, 4)
		);

		switch (method) {
			case "post":
				axios
					.post(base_url, params, headers)
					.then(resolve)
					.catch(reject);
				break;
			case "put":
				axios
					.put(base_url, params, headers)
					.then(resolve)
					.catch(reject);
				break;
			case "get":
				axios.get(base_url, headers).then(resolve).catch(reject);
				break;
			case "delete":
				axios.delete(base_url, headers).then(resolve).catch(reject);
				break;
			case "patch":
				axios
					.patch(base_url, params, headers)
					.then(resolve)
					.catch(reject);
				break;
			default:
				file_upload(base_url, params.file).then(resolve).catch(reject);
				break;
		}
	});
};
