<template>
	<div class="w-full navbar-background nav" id="nav">
		<div class="container mx-auto px-12 py-7 md:px-24 lg:px-36 z-10">
			<!-- ------------------------- For Desktop  ------------------------- -->
			<div class="xl:flex flex-row justify-between hidden">
				<div class="flex flex-row items-center">
					<router-link to="/"
						><img src="@/assets/image/website/logo.png"
					/></router-link>
				</div>
				<div class="flex flex-row items-center hide-nav">
					<template v-for="(menu_item, i) in menu_links">
						<a
							:key="'menu_' + i"
							:href="menu_item.link"
							v-if="menu_item.link != '/'"
						>
							<div
								class="px-6 py-2 mt-2 md:mt-3 rounded-xl"
								:class="{
									'bg-gradient-to-t from-gray-600 via-gray-800 to-gray-700 shadow-inner':
										menu_item.decorate
								}"
							>
								<div
									class="font-bold"
									:class="{
										'text-white': menu_item.value != '|',
										'text-white text-opacity-25':
											menu_item.value == '|'
									}"
								>
									{{ menu_item.value }}
								</div>
							</div>
						</a>
					</template>
				</div>
				<!-- <div class="flex flex-row items-center">
					<div class="mx-4 cursor-pointer f-18 nav-link font-normal">
						How this works?
					</div>
					<div class="mx-4 cursor-pointer f-18 nav-link font-normal">
						Payment Types
					</div>
				</div>
				<div class="flex flex-row items-center f-18 font-normal">
					<button class="mx-4 focus:outline-none nav-link">
						Log In
					</button>
					<button
						class="registration-button f-15 tracking-wider text-white focus:outline-none "
					>
						Register
					</button>
				</div> -->
			</div>
			<!-- ------------------------- For Mobile & Ipad View ------------------------- -->
			<div class="xl:hidden hide-nav">
				<div class="flex">
					<div @click="showMenu = !showMenu">
						<img
							class="h-8 menu-icon cursor-pointer"
							style="filter: invert(100%)"
							src="@/assets/image/menu.svg"
						/>
					</div>
					<div class="my-auto pl-6">
						<router-link to="/"
							><img
								class="h-5"
								src="@/assets/image/website/logo.png"
							/>
						</router-link>
					</div>
				</div>
				<transition
					enter-active-class="transition ease-in duration-700"
					enter-class="transform opacity-0 scale-95"
					enter-to-class="transform opacity-100 scale-100"
					leave-active-class="transition ease-in duration-75"
					leave-class="transform opacity-100 scale-100"
					leave-to-class="transform opacity-0 scale-95"
				>
					<div
						v-if="showMenu"
						class="origin-top-right fixed left-0 top-0 w-full"
					>
						<div class="bg-gray-900 h-screen p-6 md:p-12">
							<div
								class="w-full flex flex-row justify-between p-4"
								@click="showMenu = false"
							>
								<div
									class="text-4xl md:text-7xl text-white text-opacity-30 font-bold"
								>
									Menu
								</div>
								<img
									class="h-8 menu-icon cursor-pointer"
									style="filter: invert(100%)"
									src="@/assets/image/close.svg"
								/>
							</div>
							<div>
								<a
									v-for="(menu_item, i) in menu_links"
									:key="'menu_' + i"
									:href="menu_item.link"
								>
									<div
										class="px-6 py-4 mt-2 md:mt-3 bg-opacity-25 rounded-xl"
										v-if="menu_item.value != '|'"
									>
										<div
											class="text-2xl md:text-4xl"
											:class="{
												'font-extrabold w-max bg-clip-text text-transparent bg-gradient-to-r from-pink-600 to-purple-500':
													menu_item.decorate,
												'font-bold text-white':
													!menu_item.decorate
											}"
										>
											{{ menu_item.value }}
										</div>
									</div>
								</a>
							</div>
							<div
								class="bg-gray-600 bg-opacity-25 my-6"
								style="height: 1px"
							></div>
							<div
								class="text-center f-15 font-bold text-gray-700"
							>
								© Paydeck Technologies Pvt Ltd 2020
								<br />All Rights Reserved <br /><br />Call
								Support: 0120-6856244<br />(10 Am - 6 Pm -
								Mon-Fri)
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showMenu: false,
			menu_links: [
				{
					link: "/",
					value: "Home",
					decorate: false
				},
				{
					link: "/page/how-it-works",
					value: "How Paydeck Works?",
					decorate: false
				},
				{
					link: "/page/payment-types",
					value: "Payment Types",
					decorate: false
				},
				{
					link: "#",
					value: "|",
					decorate: false
				},
				{
					link: "https://app.paydeck.in/user/login/new",
					value: "Register",
					decorate: false
				},
				{
					link: "https://app.paydeck.in/user/login",
					value: "Login",
					decorate: true
				}
			]
		};
	},
	mounted() {
		let myNav = document.getElementById("nav");
		window.onscroll = function () {
			"use strict";
			if (document.body.scrollTop >= 280) {
				myNav.classList.add("scroll");
			} else {
				myNav.classList.remove("scroll");
			}
		};
	}
};
</script>

<style scoped>
.navbar-background {
	background: linear-gradient(
		rgba(255, 255, 255, 0.1),
		rgba(255, 255, 255, 0)
	);
	/* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
}
.registration-button {
	/* position: absolute; */
	width: 130px;
	height: 45px;
	background: rgba(57, 19, 184, 0.74);
	border-radius: 5px;
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.registration-button:hover {
	transform: translateY(-5px);
	box-shadow: 10px 1px 3px rgba(0, 0, 0, 0.05),
		10px 20px 40px rgba(92, 103, 153, 0.2);
}
.full-container-svg img {
	position: absolute;
	width: 100vw;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: -1;
}

@media screen and (max-width: 770px) {
	.full-container-svg img {
		position: absolute;
		width: inherit;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: -1;
		/* background-color: khaki; */
	}
}
</style>
