<template>
	<div
		v-for="(s_item, index) in sections"
		class="pt-12 text-gray-500"
		:key="'section_' + index"
	>
		<div
			class="text-3xl lg:text-4xl font-bold xl:pt-6 text-black"
			v-if="s_item.section_type == 'header'"
			v-html="s_item.section_header"
		></div>
		<div
			class="text-3xl lg:text-4xl font-bold text-center"
			v-if="s_item.section_type == 'header_centered'"
			v-html="s_item.section_sub_header"
		></div>
		<div
			class="text-lg lg:text-xl"
			v-if="s_item.section_type == 'paragraph'"
			v-html="s_item.section_text.replace(/\n/g, ' <br />')"
		></div>

		<div class="text-lg" v-if="s_item.section_type == 'content_list'">
			<div
				class="flex flex-row"
				v-for="(item, k) in s_item.section_captions"
				:key="'content_list_item_' + k"
			>
				<div class="p-2">
					<svg height="15" width="15">
						<circle cx="5" cy="5" r="4" fill="black" />
					</svg>
				</div>
				<div v-html="item"></div>
			</div>
		</div>

		<img
			class="rounded-xl w-full pb-6"
			v-if="s_item.section_type == 'img_full_width'"
			:src="image_base_url + s_item.section_image"
		/>
		<div
			class="text-lg italic text-center pt-6 font-btr"
			v-if="s_item.section_type == 'img_full_width'"
			v-html="s_item.section_sub_header"
		></div>
		<div
			class="text-lg italic pt-6 font-btr"
			v-if="s_item.section_type == 'img_caption'"
			v-html="s_item.section_text.replace(/\n/g, ' <br />')"
		></div>

		<!-- <div
						class="h-1 bg-gray-100"
						v-if="index == sections.length - 1"
					></div> -->

		<blockquote
			class="text-lg font-btr"
			v-if="s_item.section_type == 'quote'"
		>
			<span
				v-html="s_item.section_header.replace(/\n/g, ' <br />')"
			></span>
			<cite
				v-html="s_item.section_sub_header.replace(/\n/g, ' <br />')"
			></cite>
		</blockquote>

		<div
			class="grid grid-cols-1 md:grid-cols-2 items-center py-12"
			v-if="
				['img_para_right', 'img_para_left'].indexOf(
					s_item.section_type
				) != -1
			"
		>
			<div class="pr-6" v-if="s_item.section_type == 'img_para_left'">
				<img
					class="mx-auto w-max rounded-xl"
					:src="image_base_url + s_item.section_image"
					v-if="s_item.section_image.length > 2"
				/>
			</div>
			<div class="">
				<div
					class="text-2xl text-gray-500 pb-6 font-semibold"
					v-html="s_item.section_header"
				></div>
				<div
					class="text-lg"
					v-html="s_item.section_text.replace(/\n/g, ' <br />')"
				></div>
			</div>
			<div class="pl-6" v-if="s_item.section_type == 'img_para_right'">
				<img
					class="mx-auto w-max rounded-xl"
					:src="image_base_url + s_item.section_image"
					v-if="s_item.section_image.length > 2"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import common from "../mixins/common";
export default {
	props: ["sections"],
	data() {
		return {};
	},
	mixins: [common],
	mounted() {}
};
</script>
