<template>
	<div>
		<div v-for="(item, index) in faqs" :key="index" class="break-words">
			<div class="text-3xl font-bold text-black py-6">
				{{ item.title }}
			</div>
			<!-- p-6 2xl:p-12 border border-gray-100 shadow-md md:bg-white rounded-lg -->
			<div
				class="mb-12 text-sm md:text-xl"
				v-if="windowWidth > 1080"
				v-html="item.description"
			></div>
			<div
				v-else-if="
					item.description.indexOf('<table') != -1 ||
					item.description.indexOf('<img') != -1 ||
					item.description.indexOf('<video') != -1 ||
					item.description.indexOf('<iframe') != -1
				"
			>
				<div class="text-lg text-red-400 mb-4">
					Scroll to view full content ⮂
				</div>
				<div
					class="mb-12 py-6 border-b-2 border-t-2 border-gray-200 md:bg-white"
				>
					<div style="overflow-x: scroll">
						<div
							class="text-sm md:text-xl"
							style="width: 1000px"
							v-html="item.description"
						></div>
					</div>
				</div>
			</div>
			<div
				class="mb-12 md:bg-white rounded-lg text-sm md:text-xl"
				v-else
				v-html="item.description"
			></div>
			<div
				class="w-full h-6 opacity-30 mb-12"
				style="
					background-image: url('https://www.emojiall.com/images/svg/emojitwo/3030.svg');
					background-repeat: repeat;
					background-size: contain;
				"
			></div>
		</div>
	</div>
</template>

<script>
import common from "../mixins/common";
export default {
	props: ["faqs"],
	data() {
		return {};
	},
	mixins: [common],
	mounted() {}
};
</script>
