<template>
	<div class="w-full footer-background link-sheet-5 bottom-0">
		<div class="container mx-auto text-left p-6 md:p-16 lg:px-24 lg:py-16">
			<div id="footer">
				<div
					class="grid grid-cols-1 md:grid-cols-4 md:gap-10 font-normal"
				>
					<div
						class="pr-6"
						v-for="(list, i) in footer_links"
						:key="i"
					>
						<div
							class="text-xl font-bold pb-4 text-gray-400"
							v-html="list._id"
						></div>
						<a
							v-for="(item, i) in list.links"
							:key="i"
							:href="'/page/' + item.footer_link"
						>
							<div
								class="text-sm font-bold text-blue-600 my-1 pb-4"
								v-html="item.footer_label"
							></div>
						</a>
					</div>

					<!-- <div class="f-15 font-bold text-blue-600">
					<div class="my-1 pb-6">
						<div class="cursor-pointer">About Us</div>
					</div>
					<div class="my-1 pb-6">
						<div class="cursor-pointer">Terms Of Services</div>
					</div>
					<div class="my-1 pb-6">
						<div class="cursor-pointer">Privacy</div>
					</div>
					<div class="my-1 pb-6">
						<div class="cursor-pointer">Contact Us</div>
					</div>
				</div>
				<div class="f-15 font-bold text-blue-600">
					<div class="my-1 pb-6">
						<div class="cursor-pointer">FAQ</div>
					</div>
					<div class="my-1 pb-6">
						<div class="cursor-pointer">Refund Policy</div>
					</div>
					<div class="my-1 pb-6">
						<div class="cursor-pointer">
							Fraud Reporting & Management
						</div>
					</div>
					<div class="my-1 pb-6">
						<div class="cursor-pointer">Help</div>
					</div>
				</div> -->
					<div
						class="col-span-1 md:col-span-2 font-medium text-gray-600 pt-6 md:pt-0"
					>
						<a href="/">
							<img src="@/assets/image/website/logo-dark.png"
						/></a>
						<div
							class="mb-2 mt-6"
							v-html="site_data['footer-about']"
						></div>
						<div class="flex flex-row text-2xl mt-6">
							<a
								class="underline pr-6"
								href="https://www.facebook.com/paydeck.in/"
							>
								<i class="lni lni-facebook-original"></i>
							</a>
							<a
								class="underline pr-6"
								href="https://twitter.com/paydeck"
							>
								<i class="lni lni-twitter-original"></i>
							</a>
							<a
								class="underline"
								href="https://www.linkedin.com/company/paydeck%E2%84%A2"
							>
								<i class="lni lni-linkedin-original"></i>
							</a>
						</div>
					</div>
				</div>

				<div
					class="bg-gray-200 my-6 rounded-full"
					style="height: 2px"
				></div>
			</div>
			<div
				class="text-center font-medium f-15 text-gray-400"
				v-html="
					site_data['footer-copyright'] +
					' - ' +
					site_data['footer-rights']
				"
			></div>
		</div>
	</div>
</template>

<script>
import service_manager from "../api/service_manager.js";
export default {
	data() {
		return {
			footer_links: [],
			site_data: {
				"footer-about": "",
				"footer-contact-header": "",
				"footer-contact-link": "",
				"footer-contact-link-label": "",
				"footer-copyright": "",
				"footer-registration-button-text": "",
				"footer-registration-header": "",
				"footer-registration-input-hint": "",
				"footer-rights": "",
				"footer-social-header": "",
				"footer-subscription": "",
				"footer-subscription-button-text": "",
				"footer-subscription-input-hint": ""
			}
		};
	},
	methods: {
		getLinks() {
			service_manager("patch", "/api/data/aggregate/site_footer_links", {
				query: [
					{
						$match: {
							footer_type: {
								$ne: "Top Menu"
							}
						}
					},
					{
						$sort: {
							updatedAt: 1
						}
					},
					{
						$group: {
							_id: "$footer_type",
							links: {
								$push: "$$ROOT"
							}
						}
					},
					{
						$sort: {
							_id: 1
						}
					}
				]
			})
				.then((res) => {
					// console.log(res.data.response);
					this.footer_links = res.data.response.data;
				})
				.catch((err) => {
					// console.log(err);
				});
		},
		getSiteData() {
			service_manager("post", "/api/data/fetch/site_data/0/0", {
				query: {
					data_alias: {
						$regex: "footer-"
					}
				},
				show: {},
				sort: {}
			})
				.then((res) => {
					// console.log( "Site Data ===================================" );
					// // console.log(res.data.response.data);
					var site_data = res.data.response.data;
					for (var i = 0; i < site_data.length; i++) {
						this.site_data[site_data[i]["data_alias"]] =
							site_data[i]["data_value"];
					}
				})
				.catch((err) => {
					// console.log(err);
				});
		}
	},
	mounted() {
		this.getLinks();
		this.getSiteData();
	}
};
</script>

<style scoped>
.footer-background {
	background: #ffffff;
	box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(40px);
	/* bottom: 0px !important; */
}
.span-lightblue {
	color: #2249b3;
}
/* .:nth-child(-n + 3) {
	border-bottom: 1px solid rgba(0, 0, 0, 0.09) !important;
	width: 150px !important;
}
.-1 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.09);
} */
</style>
