<template>
	<!-- :class="{
			'bg-gray-100': page.page_link == 'frequently-asked-questions',
			'bg-white': page.page_link != 'frequently-asked-questions'
		}" -->
	<div class="w-full">
		<div
			class="w-full zero relative"
			:style="{
				backgroundImage:
					'url(' + require('@/assets/image/Path_4.svg') + ')'
			}"
		>
			<div class=" paydeck-masthead-image absolute z-10">
				<svg
					width="1438"
					height="568"
					viewBox="0 0 1438 568"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						id="Path 5"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M808.255 560.386C713.308 547.066 680.967 422.422 587.32 403.407C472.183 380.029 352.098 475.015 239.1 444.819C134.903 416.976 34.7088 339.607 7.77722 248.543C-20.8267 151.824 87.1576 61.4367 96.3161 -37.9764C103.908 -120.381 35.5194 -200.997 56.6177 -281.607C77.7392 -362.307 133.626 -441.442 213.805 -486.869C295.685 -533.26 420.162 -484.815 500.535 -533.119C588.331 -585.886 577.067 -723.669 673.753 -763.303C760.849 -799.006 861.153 -732.516 956.66 -720.097C1055.79 -707.208 1170.25 -737.549 1252.5 -688.051C1335.58 -638.061 1359.98 -540.733 1392.19 -458.006C1420.28 -385.887 1421.56 -309.839 1429.28 -234.103C1436.12 -167.013 1446.65 -100.491 1435.44 -33.8793C1424.43 31.5252 1375.3 87.3885 1364.15 152.774C1348.29 245.734 1425.45 359.975 1355.91 432.246C1291.65 499.032 1156.51 446.864 1059.27 469.616C970.926 490.287 898.712 573.076 808.255 560.386Z"
						fill="url(#paint0_linear)"
					/>
					<defs>
						<linearGradient
							id="paint0_linear"
							x1="1468.82"
							y1="-614.41"
							x2="28.0486"
							y2="-847.205"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#FF86B0" />
							<stop offset="1" stop-color="#6630FF" />
						</linearGradient>
					</defs>
				</svg>
			</div>
			<div
				class="container z-30 mx-auto link-sheet-20 px-12 py-36 md:p-24 lg:p-36"
			>
				<div class="w-full mx-auto">
					<div
						class="text-5xl md:text-6xl xl:text-7xl font-extrabold text-white py-16 lg:pt-20 paydeck-masthead-header"
					>
						You're Lost
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="bg-gradient-to-tr from-purple-900 via-pink-600 to-pink-600">
			<div class="container mx-auto px-12 md:px-24 lg:px-36 ">
				<div
					class="pt-48 pb-12 text-4xl md:text-5xl font-bold text-white"
				>
					<span v-if="ifSupport">
						We're here to help!
					</span>
					<span v-else>
						{{ page.page_caption }}
					</span>
				</div>
			</div>
		</div> -->
		<div
			class="z-30 container mx-auto py-12 px-12 md:px-24 lg:px-36 md:pb-24 rounded-xl"
		></div>
	</div>
</template>

<style scoped>
.paydeck-masthead-header {
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.paydeck-masthead-image * {
	width: 0vw;
	margin-top: -3vh;
	max-width: unset;
	overflow: hidden !important;
	z-index: -1;
}
.zero {
	width: 100vw;
	padding-bottom: 5vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.link-sheet-20 {
	width: 100%;
	z-index: 10 !important;
	overflow: hidden !important;
	position: relative;
}
.span-color-4 {
	background-image: linear-gradient(#ff9680, #ffccde) !important;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
}
.span-blue {
	color: #3913b8;
}
.span-blue-1 {
	color: #2249b3;
}
.span-white {
	color: rgba(255, 255, 255, 0.7);
}
.span-white-1 {
	color: rgba(255, 255, 255, 0.87);
}
.get-started-button {
	align-items: center;
	padding: 15px 33px;
	width: 197px;
	height: 54px;
	background: linear-gradient(91.96deg, #3913b8 0%, #ba75ff 100%);
	box-shadow: 0px 20px 40px rgba(113, 62, 215, 0.3);
	border-radius: 30px;
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.get-started-button:hover {
	transform: translateY(-5px);
	box-shadow: 0px 20px 40px rgba(113, 62, 215, 0.8);
}
.separate {
	bottom: 100px !important;
}

.payment-payout-background {
	/* background: linear-gradient(
		139.43deg,
		#3913b8 12.46%,
		rgba(57, 19, 184, 0) 101.08%
	); */
	background-image: radial-gradient(
		circle 300px at 8% 89.3%,
		rgba(20, 157, 208, 1) 0%,
		rgba(140, 63, 226, 1) 90%
	);
	position: relative;
}
.limit-card {
	padding: 30px;
	background: rgba(88, 105, 255, 0.1);
	backdrop-filter: blur(30px);
	border-radius: 20px;
}
.simple-card {
	background: #ffffff;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05),
		0px 20px 40px rgba(92, 103, 153, 0.2);
	border-radius: 30px;
	padding: 20px;
	transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.simple-card:hover {
	transform: translateY(-5px);
	box-shadow: 10px 1px 3px rgba(0, 0, 0, 0.05),
		10px 20px 40px rgba(92, 103, 153, 0.2);
}
.rounded-number {
	background: #dbe1ff;
	height: 30px !important;
	width: 30px;
	border-radius: 50%;
	text-align: center;
	padding: 5px;
}
.chip-background {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}
.start-now {
	position: relative;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0px 20px 40px rgba(23, 0, 102, 0.3);
	border-radius: 30px;
	transition: 0.3s ease-in-out;
	width: 280px;
	cursor: pointer;
}
.start-now:hover {
	box-shadow: 0px 20px 40px rgba(23, 0, 102, 0.6);
	transform: translateY(-4px);
}

/* Responsive Screen */
/* FOR 2XL Displays */
@media only screen and (min-device-width: 1441px) and (max-device-width: 2160px) {
	.paydeck-masthead-image * {
		width: 0vw;
	}
	.zero {
		width: 100vw;
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
/* FOR XL Displays */
@media only screen and (min-device-width: 1022px) and (max-device-width: 1440px) {
	.paydeck-masthead-image * {
		width: 0vw;
	}
	.zero {
		width: 100vw;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
/* FOR TABLET landscape*/
@media only screen and (max-device-width: 1023px) and (min-device-width: 768px) and (orientation: landscape) {
	.paydeck-masthead-image * {
		width: 100vw;
	}
	.zero {
		background-size: 0%;
	}
}
/* FOR TABLET portrait*/
@media only screen and (max-device-width: 1023px) and (min-device-width: 768px) and (orientation: portrait) {
	.paydeck-masthead-image * {
		width: 100vw;
	}
	.zero {
		background-size: 0%;
	}
}
/* FOR MOBILE */
@media only screen and (max-device-width: 767px) {
	.paydeck-masthead-image * {
		opacity: 0;
	}
	.zero {
		width: 100vw;
		background-position: 24%;
		background-repeat: no-repeat;
		background-size: cover;
		padding-bottom: 10vh;
		margin-bottom: -10vh;
	}
	.link-sheet-20 {
		width: 100%;
		z-index: 10 !important;
		overflow: hidden !important;
		position: relative;
	}
}
</style>
