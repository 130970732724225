var axios = require("axios");
import file_upload from "./file_upload.js";

export default (key, method, path, params) => {
    return new Promise((resolve, reject) => {
        var base_url = path;

        var headers = {
            headers: {
                "content-type": "application/json",
                // 'Access-Control-Allow-Origin': origin,
                // 'Access-Control-Allow-Headers': 'Origin, Content-Type',
                "X-Requested-With": "XMLHttpRequest",
                Authorization: key
            },
            auth: {
                username: "saurabh@paydeck.in",
                password: "letmein123"
            }
            // proxy: {
            //     host: '127.0.0.1',
            //     port: 8081
            // }
        };

        // console.log(method.toUpperCase(), base_url, params);

        switch (method) {
            case "post":
                axios
                    .post(base_url, params, headers)
                    .then(resolve)
                    .catch(reject);
                break;
            case "put":
                axios
                    .put(base_url, params, headers)
                    .then(resolve)
                    .catch(reject);
                break;
            case "get":
                axios
                    .get(base_url, headers)
                    .then(resolve)
                    .catch(reject);
                break;
            case "delete":
                axios
                    .delete(base_url, headers)
                    .then(resolve)
                    .catch(reject);
                break;
            case "patch":
                axios
                    .patch(base_url, params, headers)
                    .then(resolve)
                    .catch(reject);
                break;
            default:
                file_upload(base_url, params.file)
                    .then(resolve)
                    .catch(reject);
                break;
        }
    });
};
