"use strict";
const axios = require("axios");
export default (url, file, name = "file") => {
    if (typeof url !== "string") {
        throw new TypeError(`Expected a string, got ${typeof url}`);
    }
    let formData = new FormData();
    formData.append(name, file);
    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    };
    return axios.post(url, formData, config);
};
