import service_manager from "../api/service_manager";
export default {
	data() {
		return {
			regex_pan: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
			limit: 0,
			skip: 0,
			page_hits: 0,
			user_hits: 0,
			loading: false,
			error: {
				status: false,
				positive: false,
				header: "",
				message: ""
			},
			windowWidth: 0,
			windowHeight: 0,
			months: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			],
			// juspay_base_url: "https://sandbox.juspay.in/",
			juspay_base_url: "https://api.juspay.in/",
			juspay_key: "9E4B78DB25FD42AD99FA9BDE1E55F00D:",
			juspay_merchant_id: "paydeck",
			verified: true,
			ifsc_api_link: "https://ifsc.razorpay.com/"
		};
	},
	computed: {
		current_url: () => {
			return window.location.href;
		},
		// images are stored in API folder in /public/uploads
		image_base_url: () => {
			// SERVER_TEST
			var url = "http://192.168.1.11:3332";
			if (process.env.NODE_ENV == "production") {
				// SERVER_PRODUCTION
				url = "https://api2.paydeck.in";
			}
			if (process.env.NODE_ENV == "staging_prod") {
				// SERVER_STAGING_PROD
				url = "https://api3.paydeck.in";
			}
			if (process.env.NODE_ENV == "staging") {
				// SERVER_STAGING
				url = "https://pd.omnibuz.com";
			}
			return url;
		},
		// product page is hosted at app.paydeck.in
		link_base_url: () => {
			// SERVER_TEST
			var url = "http://192.168.1.11:8081/";
			if (process.env.NODE_ENV == "production") {
				// SERVER_PRODUCTION
				url = "https://app.paydeck.in/";
			}
			if (process.env.NODE_ENV == "staging_prod") {
				// SERVER_STAGING_PROD
				url = "https://demo21.paydeck.in/";
			}
			if (process.env.NODE_ENV == "staging") {
				// SERVER_STAGING
				url = "https://pdw.paydeck.in/";
			}
			return url;
		},
		menu_dict() {
			return this.$store.state.menu;
		}
	},
	methods: {
		scrollToTop() {
			$("html,body").animate({ scrollTop: 0 }, "fast");
		},
		scrollToBottom() {
			$("html,body").animate(
				{ scrollTop: document.body.scrollHeight },
				"fast"
			);
		},
		getTwoDigitString(d) {
			if (d.toString().length == 1) {
				return "0" + d.toString();
			} else return d.toString();
		},
		daysInMonth(month, year) {
			return new Date(year, month, 0).getDate();
		},
		toINR(amount) {
			return amount.toLocaleString("en-IN", {
				maximumFractionDigits: 2,
				style: "currency",
				currency: "INR"
			});
		},
		weekAndDay(d) {
			var getTot = this.daysInMonth(d.getMonth(), d.getFullYear()); //Get total days in a month
			var sat = new Array(); //Declaring array for inserting Saturdays
			var sun = new Array(); //Declaring array for inserting Sundays

			for (var i = 1; i <= getTot; i++) {
				//looping through days in month
				var newDate = new Date(d.getFullYear(), d.getMonth(), i);
				if (newDate.getDay() == 0) {
					//if Sunday
					sun.push(i);
				}
				if (newDate.getDay() == 6) {
					//if Saturday
					sat.push(i);
				}
			}
			return {
				sat: sat,
				sun: sun
			};
		},
		ordinal_suffix_of(i) {
			var j = i % 10,
				k = i % 100;
			if (j == 1 && k != 11) {
				return i + "st";
			}
			if (j == 2 && k != 12) {
				return i + "nd";
			}
			if (j == 3 && k != 13) {
				return i + "rd";
			}
			return i + "th";
		},
		printJSON(json_object) {
			var jsonPretty = JSON.stringify(json_object, null, 4);
			console.log(jsonPretty);
		},
		stripStyles(text) {
			var qtext = text.split('"');
			var string = "";
			for (var i = 0; i < qtext.length; i++) {
				if (i % 2 == 0)
					string += qtext[i]
						.replace("style=", "")
						.replace("data-identifyelement=", "")
						.replace("dir=", "");
				else {
					if (i - 1 >= 0) {
						if (
							qtext[i - 1].indexOf("style=") == -1 &&
							qtext[i - 1].indexOf("data-identifyelement=") ==
								-1 &&
							qtext[i - 1].indexOf("dir=") == -1
						) {
							string += '"' + qtext[i] + '"';
						}
					}
				}
			}
			return string
				.split("<br>")
				.join("")
				.split("<br >")
				.join("")
				.split(".<strong >")
				.join(".<br><strong >")
				.split(".<strong>")
				.join(".<br><strong>")
				.split("><strong >")
				.join("><br><strong >")
				.split("><strong>")
				.join("><br><strong>")
				.split(".</strong>")
				.join(".</strong> ");
		},
		getFormatDate(value) {
			if (value) {
				return moment(String(value)).format(
					"ddd, Do MMMM, YYYY hh:mm a"
				);
			}
		},
		getAddedValue(list) {
			var total = 0;
			for (var i = 0; i < list.length; i++) {
				total += parseFloat(list[i]);
			}
			return total;
		},
		getID(value) {
			if (value != undefined) {
				var valueArr = value.split("_");
				var valueArrStr = valueArr[valueArr.length - 1];
				for (var i = valueArr.length - 2; i > -1; i--) {
					valueArrStr = valueArr[i][0].toUpperCase() + valueArrStr;
				}
				return valueArrStr;
			} else {
				return "";
			}
		},
		numberRound(value, decimals) {
			return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
			// return Math.round((num + Number.EPSILON) * 100) / 100;
			// return parseFloat(value).toFixed(decimals);
		},
		round: (number, decimals) => {
			var number_to_round = 0;
			if (typeof number == "String") {
				number_to_round = parseFloat(number_to_round);
			}
			var decimal_power = Math.pow(10, decimals);
			return Math.round(number * decimal_power) / decimal_power;
		},
		url(value) {
			window.location.pathname = value;
		},
		validateEmail(email) {
			var re =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		hasText(params) {
			var validated = true;
			for (var key in params) {
				var value = params[key];
				// console.log(key, value);
				if (value != [] && (value == undefined || value == "")) {
					// console.log(key, ":", value);
					validated = false;
				}
			}
			return validated;
		},
		isPhoneNumber(value) {
			var stringVal = value + "";
			return stringVal.length == 10;
		},
		checkForSpaces(event) {
			var val = event.target.value;
			event.target.value = val.replace(" ", "");
		},
		getFormattedDate(time) {
			var date = new Date(time);
			var day = date.getDate();
			var month = date.getMonth() + 1;
			var year = date.getFullYear();
			return day + "-" + month + "-" + year;
		},
		toLink(value) {
			return value
				.toLowerCase()
				.split(" ")
				.join("-")
				.replace(/[^a-z0-9\-]/g, "");
		},
		getUserIP() {
			return new Promise((resolve, reject) => {
				this.httpGetAsync("https://api.ipify.org?format=json")
					.then((data) => {
						resolve(data);
					})
					.catch((error) => {
						resolve(
							JSON.stringify({
								ip: "0.0.0.0"
							})
						);
					});
			});
		},
		httpGetAsync(url) {
			// console.log("XHR GET", url);
			return new Promise((resolve, reject) => {
				var xmlHttp = new XMLHttpRequest();
				xmlHttp.onreadystatechange = function () {
					if (this.readyState == 4 && this.status == 200)
						resolve(this.responseText);
				};
				xmlHttp.open("GET", url, true); // true for asynchronous
				xmlHttp.send(null);
			});
		},
		getWindowWidth(event) {
			this.windowWidth = document.documentElement.clientWidth;
			// console.log(this.windowWidth);
		},
		getWindowHeight(event) {
			this.windowHeight = document.documentElement.clientHeight;
		}
	},
	mounted() {
		var url = window.location.pathname;

		this.$nextTick(function () {
			window.addEventListener("resize", this.getWindowWidth);
			window.addEventListener("resize", this.getWindowHeight);

			//Init
			this.getWindowWidth();
			this.getWindowHeight();
		});
	}
};
