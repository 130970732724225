<template>
	<div
		v-for="(s_item, index) in sections"
		:key="index"
		class="padding-sm-top padding-sm-bottom"
	>
		<!-- <div
			class="bg-green-500 px-4 py-2 w-max rounded-2xl font-bold text-white"
		>
			{{ s_item.section_type }}
		</div> -->
		<!-- Steps -->
		<div
			class=" pb-24 lg:pb-36"
			v-if="s_item.section_type == 'three_icon_items'"
		>
			<div
				class="text-4xl font-extrabold pb-3"
				v-if="windowWidth > 767"
				v-html="s_item.section_header"
			></div>
			<div
				class="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
				v-if="windowWidth > 767"
				v-html="s_item.section_sub_header"
			></div>
			<div
				class="text-4xl font-extrabold pb-3"
				v-if="windowWidth < 768"
				v-html="s_item.section_header"
			></div>
			<div
				class="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
				v-if="windowWidth < 768"
				v-html="s_item.section_sub_header"
			></div>

			<div class="grid grid-cols-1 xl:grid-cols-3 gap-12 mt-12">
				<div
					class="p-2 grid grid-cols-1 md:grid-cols-4 xl:flex xl:flex-col justify-items-start items-center"
					v-for="(item, index) in s_item.section_items"
					:key="'section_items_2_' + index"
				>
					<div
						class="w-full xl:w-full rounded-xl bg-gray-100 bg-opacity-50"
					>
						<img
							class="w-full xl:w-64 p-4 xl:p-6 xl:mx-auto"
							:src="image_base_url + item.image"
							v-if="item.image.length > 2"
						/>
					</div>
					<div class=" col-span-3  px-6 py-6 md:py-0 xl:py-6">
						<div
							class="text-xl font-bold"
							v-html="item.header"
						></div>
						<div
							class="text-sm pt-3"
							v-html="item.sub_header"
						></div>
					</div>
				</div>
			</div>
		</div>

		<!-- Full Image Right/Left -->
		<!-- Section Right/Left -->
		<div
			class="pb-24 lg:pb-36"
			v-if="
				[
					'section_full_img_right',
					'section_full_img_left',
					'section_left',
					'section_right'
				].indexOf(s_item.section_type) != -1
			"
		>
			<div
				v-if="
					['section_left', 'section_right'].indexOf(
						s_item.section_type
					) != -1
				"
			>
				<div
					class="text-4xl font-extrabold pb-3"
					v-html="s_item.section_header"
				></div>
				<div
					class="text-2xl font-bold pb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
					v-html="s_item.section_sub_header"
				></div>
			</div>
			<div
				class="grid"
				:class="{
					'grid-cols-1 xl:grid-cols-2':
						[
							'section_full_img_right',
							'section_full_img_left'
						].indexOf(s_item.section_type) != -1,
					'grid-cols-1 md:grid-cols-3':
						['section_left', 'section_right'].indexOf(
							s_item.section_type
						) != -1
				}"
			>
				<div
					class="h-72 xl:w-full xl:h-full rounded-xl paydeck-aligned-image"
					:style="{
						'background-image':
							'url(' + image_base_url + s_item.section_image + ')'
					}"
					v-if="
						s_item.section_type == 'section_full_img_left' &&
							windowWidth > 767
					"
				></div>
				<img
					class="rounded-xl h-48 mr-auto my-auto"
					v-if="
						s_item.section_type == 'section_left' &&
							windowWidth > 767
					"
					:src="image_base_url + s_item.section_image"
				/>
				<div
					:class="{
						'pl-6':
							['section_left', 'section_full_img_left'].indexOf(
								s_item.section_type
							) != -1 && windowWidth > 767,
						'pr-6':
							['section_full_img_right', 'section_right'].indexOf(
								s_item.section_type
							) != -1 && windowWidth > 767,
						'md:col-span-2':
							['section_left', 'section_right'].indexOf(
								s_item.section_type
							) != -1,
						'pb-12':
							[
								'section_full_img_left',
								'section_full_img_right'
							].indexOf(s_item.section_type) != -1
					}"
				>
					<div
						v-if="
							[
								'section_full_img_right',
								'section_full_img_left'
							].indexOf(s_item.section_type) != -1
						"
					>
						<div
							class="text-4xl font-extrabold pb-3"
							v-html="s_item.section_header"
						></div>
						<div
							class="text-2xl font-bold pb-6 bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
							v-html="s_item.section_sub_header"
						></div>
					</div>
					<!-- <div class="ui yellow label horizontal-short-bar"></div> -->
					<div
						class="flex flex-row text-lg"
						v-for="(list_item, i) in s_item.section_captions"
						:key="'section_captions_' + i"
					>
						<div class="p-2">
							<svg height="15" width="15">
								<circle cx="5" cy="5" r="4" fill="black" />
							</svg>
						</div>
						<div class="text-lg" v-html="list_item"></div>
					</div>
				</div>
				<div
					class="h-72 xl:w-full xl:h-full rounded-xl paydeck-aligned-image"
					:style="{
						'background-image':
							'url(' + image_base_url + s_item.section_image + ')'
					}"
					v-if="
						s_item.section_type == 'section_full_img_right' &&
							windowWidth > 767
					"
				></div>
				<img
					class="rounded-xl h-48 ml-auto my-auto"
					v-if="
						s_item.section_type == 'section_right' &&
							windowWidth > 767
					"
					:src="image_base_url + s_item.section_image"
				/>
			</div>
		</div>

		<!-- section_img_icons_right / section_img_icons_left -->
		<div
			class="pb-24 lg:pb-36"
			v-if="
				['section_img_icons_right', 'section_img_icons_left'].indexOf(
					s_item.section_type
				) != -1
			"
		>
			<div class="grid grid-cols-1 lg:grid-cols-2">
				<div class="my-auto md:pr-12 pb-12 lg:pb-0">
					<div
						class="text-xl font-bold pb-3 bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
						v-html="s_item.section_sub_header"
					></div>
					<div
						class="text-4xl font-extrabold pb-6"
						v-html="s_item.section_header"
					></div>
					<div class="w-24 bg-purple-400 h-2 my-6 rounded-xl"></div>
					<div
						class="text-lg"
						v-html="s_item.section_text.replace(/\n/g, ' <br />')"
					></div>
				</div>
				<div class="w-full my-auto">
					<div class="grid grid-cols-1 md:grid-cols-2 gap-6">
						<div
							class="p-6 text-center rounded-xl shadow-md bg-white border border-gray-100"
							v-for="(item, i) in s_item.section_items"
							:key="'section_items_1_' + i"
						>
							<img
								class="w-20 mx-auto"
								:src="image_base_url + item.image"
								v-if="item.image.length > 2"
							/>
							<div
								class="text-sm font-bold pt-3"
								v-html="item.header"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- section_full_img_back -->
		<div
			class="pb-24 lg:pb-36"
			v-if="s_item.section_type == 'section_full_img_back'"
		>
			<div
				class="p-12 md:p-24 lg:p-36 bg-gray-100 bg-opacity-50 rounded-2xl"
			>
				<div
					class="text-lg md:text-xl font-bold pb-3 text-gray-400"
					v-html="s_item.section_sub_header"
				></div>
				<div
					class="text-4xl md:text-5xl xl:text-7xl font-extrabold pb-3 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 via-pink-600 to-blue-700"
					v-html="s_item.section_header"
				></div>
				<!-- <div class="w-24 bg-pink-400 h-2 my-6 rounded-xl"></div> -->
				<div
					class="text-lg pt-6"
					v-html="s_item.section_text.replace(/\n/g, ' <br />')"
				></div>
			</div>
		</div>

		<!-- two_column_items -->
		<div
			class="pb-24 lg:pb-36"
			v-if="s_item.section_type == 'two_column_items'"
		>
			<div
				class="text-4xl font-extrabold pb-3"
				v-html="s_item.section_header"
			></div>
			<div
				class="text-2xl font-bold pb-3 bg-clip-text text-transparent bg-gradient-to-r from-pink-600 via-purple-600 to-blue-800"
				v-html="s_item.section_sub_header"
			></div>
			<!-- <div class="ui yellow label horizontal-short-bar"></div> -->
			<div class="grid grid-cols-2">
				<div
					class="p-6"
					v-for="(item, i) in s_item.section_items"
					:key="'section_items_' + i"
				>
					<div class="text-2xl" v-html="item.header"></div>
					<div class="text-lg" v-html="item.sub_header"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import common from "../mixins/common";
export default {
	props: ["sections"],
	data() {
		return {};
	},
	mixins: [common],
	mounted() {}
};
</script>
<style scoped>
.paydeck-aligned-image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
</style>
